import React, {useState, useEffect, useRef} from 'react';

import LoaderSimple from './subcomponents/LoaderSimple';

import FilterComponent from './Videos/FilterComponent';
import NextPrevious from './Search/NextPrevious';

import ChannelItem from './Channels/ChannelItem';
import AddChannelsByURL from './Channels/AddChannelsByURL';

import '../css/Channels.css';

import {
  apirequest,
  getAuthData,
  getSortDirection,
} from '../js/Utilities';

import {
  filterCriteria,
  addChannelState,
  stringToArray,
  setStateOnShowAddChannel,
  setStateOnAddChannelResponse,
  setStateOnCloseAddChannel,
  setStateOnSubmitAddChannel,
  setStateOnAddChannelChange,
} from '../actions/ChannelsUtils';

import {
  CHANNELS_PER_PAGE,
  API_CHANNEL_BATCH,
  API_CHANNEL_ADD_TO_SYSTEM,
  API_GROUPS_ALLGROUPS
} from '../js/Configuration';

const Channels = () => {
  const fetchCancel = new AbortController();
  const modalRef = useRef(null);

  const [channelList, setChannelList] = useState([]);
  const [channelCount, setChannelCount] = useState(0);
  const [groupsOptions, setGroupsOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(CHANNELS_PER_PAGE);
  const [pageCount, setPageCount] = useState(1);
  const [numResults, setNumResults] = useState(0);
  const [sortBy, setSortBy] = useState('channel_title');
  const [ascending, setAscending] = useState(true);
  const [filter, setFilter] = useState(filterCriteria);

  const [addChannels, setAddChannels] = useState(addChannelState);

  const getChannelBatch = () => {
    const signal = null;

    const fd = new FormData();
    fd.set('perpage', perPage);
    fd.set('page', page);
    fd.set('sort_by', sortBy);
    fd.set('filter_by', filter.searchValue);
    fd.set('filter_on', filter.filterOn);
    fd.set('order', getSortDirection(ascending));
    fd.set('token', getAuthData('token'));

    apirequest(API_CHANNEL_BATCH, {body: fd, signal}, (response) => {
      if(!response) return;
      const {
        channel_count,
        channels,
        data,
        current_page,
        pages,
        num_results
      } = response;

      if(!channels.length) {
        setChannelList([false]);
      } else {
        setChannelList(channels);
      }

      setChannelCount(channel_count);
      setPage(current_page);
      setPageCount(pages);
      setNumResults(num_results);
      setLoading(false);
    });
  }

  useEffect(() => {
    const signal =  fetchCancel.signal;

    if(!channelList.length) {
      getChannelBatch(signal);
    }

    return () => fetchCancel.abort();
  }, [channelList, fetchCancel, ascending, addChannels])

  const getGroups = () => {
    const fd = new FormData();
    fd.set('token', getAuthData('token'));
    apirequest(API_GROUPS_ALLGROUPS, {body: fd}, onGroupsResponse);
  }

  const onGroupsResponse = (data) => {
    if(!data.length) return;
    const groups = data.map((x) => {
      return {
        value: x.group_id,
        label: x.group_name
      };
    });

    setGroupsOptions(groups);

    const ac = setStateOnShowAddChannel(addChannels);
    setAddChannels(ac);
    if(Object.hasOwn(modalRef, 'current') && !!modalRef.current) {
        modalRef.current.showModal();
    }
  }

  const onFilterChangeHandler = (domEvent) => {
    const {value} = domEvent.target;
    setPage(1);
    setFilter({...filter, searchValue: value});
    setLoading(!loading);
    setChannelList([false]);
  }

  const onSortChangeHandler = (domEvent) => {
    const sort_criteria = domEvent.target.value.split('|')[0];
    setSortBy(sort_criteria)
    setPage(1);
    setLoading(true);
    setChannelList([false]);
  }

  const onDirectionChangeHandler = () => {
    setAscending(!ascending);
    setChannelList([false]);
    setLoading(!loading);
  }

  const onFilterClearHandler = () => {
    setFilter({...filter, ...filterCriteria});
    setAscending(true);
    setPage(1);
    setPageCount(1);
    setLoading(true);
    setChannelList([false]);
  }

  const onShowAddChannel = () => {
    getGroups();
  }

  const onCloseAddChannel = () => {
    if(Object.hasOwn(modalRef, 'current') && !!modalRef.current) {
      modalRef.current.close();
    }
    const acs = setStateOnCloseAddChannel(addChannels)
    setAddChannels(acs);
  }

  const onSubmitAddChannel = (domEvent) => {
    domEvent.preventDefault();

    const {
      group,
      channels,
    } = domEvent.target;

    const fd = new FormData();
    fd.set('groupid', group.value);
    fd.set('token', getAuthData('token'));

    const chnls = stringToArray(channels.value).filter((ch) => ch !== '');
    chnls.forEach( ( ch ) => {
      fd.set( 'id', ch ); // onSubmitAddChannelResponse
      apirequest(API_CHANNEL_ADD_TO_SYSTEM, {body: fd}, onSubmitAddChannelResponse);
    });
  }

  const onSubmitAddChannelResponse = (response) => {
      const ac = setStateOnAddChannelResponse(addChannels, response);
      console.log(ac)
      setAddChannels(ac);

  }

  const onAddChannelErrorClose = () => {
    setAddChannels({...addChannels, error: ''});
  }

  const getPagingComp = () => {
    return (
      <NextPrevious
        prev_page={+page > 1}
        next={getNextPage}
        prev={getPrevPage}
        per_page={+perPage}
        result_count={numResults}
        className="channels__pagination" />
    );
  }

  const getNextPage = () => {
    // If page is greater than count, clamp to the value of count.
    // Otherwise increment.
    const nextPage = ( page > pageCount) ? pageCount : page + 1;
    setLoading(true);
    setChannelList([false]);
    setPage(+nextPage);
  }

  const getPrevPage = () => {
    // If page is greater than count, clamp to the value of count.
    // Otherwise increment.
    const prevPage = (page <= 0) ? 1 : page - 1;
    setLoading(true);
    setChannelList([false]);
    setPage(+prevPage);
  }

  const getCurrentGroupFromId = (id) => {
    return groupsOptions.find((item) => item.value == id);
  }

  /* When coming from SelectMenu, may be a numeric value, not an Event object */
  const onAddChannelsChange = (domEvent) => {
    const {name, value} = domEvent.target;
    const acc = setStateOnAddChannelChange(addChannels, name, value);
    setAddChannels(acc);
  }

  const makePageCount = () => {
    let pgCount;
    if(channelList.length) {
      pgCount = `Page ${page} of ${pageCount} (${numResults} results)`;
    } else {
      pgCount = `Page – of – (0 results)`;
    }
    return <span className="channels__pgcount">{ pgCount }</span>;
  }

  let channels = <LoaderSimple open={loading} />

  if( channelList.length ) {
    const items = channelList.map( (item, i) => {
      return (
        <ChannelItem key={i}
          channelData={ item }
          groupsList={groupsOptions}
        />
      );
    });

    channels = (
      <div className="video__display video__display--grid channels__list">
        <div className="List">{ items }</div>
      </div>
    );
  } else {
    channels = <h1>No channels found.</h1>
  }

  return (
    <div className="ava__view__wrap">
      <FilterComponent
        { ...filter }
        itemsName="Channels"
        sortDefault={ sortBy }
        ascending={ ascending }
        sortData={[
          { value:'channel_title', name:'Sort by Name', type:'string' },
          { value:'channel_videocount', name:'Sort by Number of Videos', type:'num' },
          { value:'channel_dateadded', name:'Sort by Date Published', type:'num' },
          { value:'channel_datelastupdated', name:'Sort by Last Updated', type:'num' },
          { value:'curated_video_count', name:'Number of Curated Videos', type:'num' },
          { value:'channel_subscribercount', name:'Number of Subscribers', type:'num' },
          { value:'channel_category', name:'Sort by Category/Topic', type:'string' },
        ]}
        filterData={[
          { value:'channel_title', name:'Channel Title', type:'string'},
          { value:'channel_category', name:'Category/Topic', type:'string' },
          { value:'channel_keywords', name:'Keywords', type:'string' },
          { value:'channel_customtags', name:'Tags', type:'string' }
        ]}
        onSortChange={onSortChangeHandler}
        onFilterChange={onFilterChangeHandler}
        onFilterClear={onFilterClearHandler}
        onDirectionChange={onDirectionChangeHandler}
      />

      <button type="button" className="btn btn--action" onClick={onShowAddChannel}><b>Add Channels</b></button>

      <div className="channels__pagination_bar">
        { makePageCount() }
        { getPagingComp() }
      </div>

      { channels }
      { getPagingComp() }
      <AddChannelsByURL
        modalRef={modalRef}
        {...addChannels}
        options={groupsOptions}
        onChange={onAddChannelsChange}
        onClose={onCloseAddChannel}
        onSubmit={onSubmitAddChannel}
        onErrorClose={onAddChannelErrorClose} />
    </div>
  )
}

export default Channels;